.banner {
  width: 650px;
  height: auto;
  min-height: 200px;
  background-color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.banner-title {
  width: auto;
  min-width: 100px;
  margin: 32px 0px 0px 0px;
  line-height: 29px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.banner-description {
  margin-top: 11px;
  width: auto;
  min-width: 100px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.banner-button {
  width: auto;
  min-width: 100px;
  margin-top: 31px;
  background-color: #000;
  padding: 12px;
  color: #fff;
  border-radius: 21px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
}
.banner-button:focus {
  width: auto;
  min-width: 100px;
  margin-top: 31px;
  background-color: #000;
  padding: 12px;
  color: #000;
  border-radius: 21px;
}
.banner-hide-content {
  display: none;
}
